@import '/styles/variables.scss';
@import '/styles/helpers/responsive.scss';

.header {
  position: sticky;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: $header-height;
  background-color: var(--color-primary);
  transition: background-color .3s ease-in-out, transform .2s ease-in-out, opacity .2s ease-in-out, visibility .2s ease-in-out;

  @include tablet {
    height: $mobile-header-height;
    background-color: var(--color-white);
  }

  &.is_folded {
    transform: translateY(-#{$mobile-header-height + 1});
    opacity: 0;
    visibility: hidden;
  }

  .cart {
    position: relative;
    color: var(--color-white);
    cursor: pointer;

    @include tablet {
      color: var(--color-primary);
      margin-right: 10px;
    }
  }
  
  .with_badge {
    position: relative;

    .status_badge {
      position: absolute;
      top: -2px;
      right: 0px;
      margin-right: 0;
    }
  }

  .account {
    padding-bottom: 1px;
  }

  .cart {
    .status_badge {
      top: 4px;
      right: 3px;
    }
  }
}

.has_transparent_header, .is_login {
  position: fixed;

  @include tablet {
    .header_entry, .cart, .logo_mobile, .account {
      color: var(--color-white);
    }
  }

  &.is_sticky {
    .logo.primary {
      color: var(--color-white);
    }
  }
}

.has_transparent_header {
  background-color: transparent;

  &.is_sticky {
    background-color: var(--color-primary);
  }
}

.is_login {
  background-color: transparent;
  transition: box-shadow .3s ease-in-out, background-color .3s ease-in-out;

  @include tablet {
    background-color: transparent;

    .logo_mobile {
      color: var(--color-primary);
    }
  }

  @include mobile {
    background-color: var(--color-white);

    .header_entry, .cart, .account {
      color: var(--color-primary);
    }
  }

  &.is_sticky {
    background-color: var(--color-primary);

    @include tablet {
      background-color: var(--color-white);
      box-shadow: 10px 0 20px rgba(31, 51, 116, 0.1);
      
      .header_entry, .cart, .account {
        color: var(--color-primary);
      }
    }

    @include mobile {
      background-color: var(--color-white);
    }
  }
}

.logo {
  display: block;
  color: var(--color-white);
  transition: color .3s ease-in-out, filter .3s ease-in-out;

  @include tablet {
    display: none;
  }

  &.primary {
    color: var(--color-primary);
  }
}

.logo_mobile {
  display: none;
  width: 30px;
  height: 30px;

  @include tablet {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.header_entry {
  padding: 10px;
  margin: 0 13px;
  color: var(--color-white);
  font-weight: 400;
  font-size: var(--fs-16);
  cursor: pointer;
  transition: filter 0.3s ease-in-out;

  &:hover {
    filter: brightness(80%);
  }

  @include tablet {
    color: var(--color-primary);
  }

  &:last-child {
    margin-right: 0;
  }

  a {
    color: var(--color-white);

    @include tablet {
      color: var(--color-primary);
    }
  }

  &.active {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: calc(100% - 20px);
      height: 1px;
      background-color: var(--color-white);
      bottom: 1px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.status_badge {
  display: inline-block;
  width: 8px;
  min-width: 8px;
  height: 8px;
  margin-right: 10px;
  background-color: var(--color-alert-900);
  border-radius: 50%;

  &.green {
    background-color: var(--color-success);
  }
}
